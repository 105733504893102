/* eslint i18next/no-literal-string: 0 */
import { Box, Collapse, Heading, HStack, colors, Icon, Link } from "@biblioteksentralen/js-utils";
import { logAdminClick } from "../../../utils/analytics/Plausible";
import { HeaderFooterContainer } from "../../ContentContainer";
import { useEditInSanityContext } from "../../editInSanity/EditInSanityContext";
import { EditPencil } from "../../editInSanity/EditPencil";
import { usePreviewContext } from "../../preview/previewContext";
import { useSanityAuth } from "../../sanityAuth/useSanityAuth";

function AdminPanel() {
  const sanityAuth = useSanityAuth();
  const editInSanityContext = useEditInSanityContext();
  const previewContext = usePreviewContext();

  // Skjuler dersom preview er å for å ikke lage rar brukeropplevelse når denne brukes som preview i Sanity
  const show = sanityAuth.isAuthenticated && !previewContext.isOn;

  return (
    <Box position="sticky" top={-1} zIndex={1000} boxShadow={show ? "md" : "none"}>
      <Collapse in={show} unmountOnExit>
        <Box backgroundColor={colors.alertYellow} opacity={0.925} color="gray.900">
          <HeaderFooterContainer
            padding="0.5rem 1rem .6rem"
            display="flex"
            flexWrap="wrap"
            gridGap="0.5rem 1rem"
            justifyContent="space-between"
            alignItems="center"
          >
            <HStack
              divider={
                <Box transform="translateY(-.1em)" border="none">
                  |
                </Box>
              }
            >
              <Heading as="h4" size="sm" display="flex" gridGap=".5rem" alignItems="center">
                Admin
              </Heading>
              <Box>{sanityAuth.name}</Box>
            </HStack>
            <Link href="/cms/" onClick={() => logAdminClick("Gå til editor")}>
              Gå til editor
            </Link>
            <Link
              display="flex"
              gap="0.5rem"
              alignItems="center"
              as="button"
              onClick={() => {
                logAdminClick(`Skru ${editInSanityContext.editMode ? "av" : "på"} redigering`);
                editInSanityContext.toggle();
              }}
            >
              <Icon flexShrink={0} as={EditPencil} transform="translateY(.1em)" /> Skru{" "}
              {editInSanityContext.editMode ? "av" : "på"} redigering
            </Link>
            <Link
              as="button"
              alignSelf="flex-end"
              onClick={() => {
                logAdminClick("Logg ut");
                sanityAuth.logOut().finally(() => sanityAuth.revalidate());
              }}
            >
              Logg ut
            </Link>
          </HeaderFooterContainer>
        </Box>
      </Collapse>
    </Box>
  );
}

export default AdminPanel;
