import { isObject, isPrimitive, unique } from "radash";

const concatenator =
  <T>(getSignature?: (item: T) => string) =>
  (leftArray: T[], rightArray: T[]) => {
    if (typeof getSignature === "function") return unique([...leftArray, ...rightArray], getSignature);
    return [...leftArray, ...rightArray];
  };

/**
 * Merge objects in such a way that arrays are concatenated, optionally making sure the elements are unique.
 */
export const mergeObjectsConcatenatingArrays = <T>(left: T, right: T, getSignature?: (item: unknown) => string): T => {
  const concatenate = concatenator(getSignature);

  // Scalars
  if (isPrimitive(left) || isPrimitive(right)) return typeof right === "undefined" ? left : right;

  // Arrays
  if (Array.isArray(right) && Array.isArray(left)) return concatenate(left, right) as T;
  if (Array.isArray(right)) return right;
  if (Array.isArray(left)) return left;

  if (!isObject(right) && !isObject(left)) {
    console.error("Unhandled case in mergeObjectsConcatenatingArrays");
    return right;
  }

  // Objects
  if (isObject(right) && !isObject(left)) return right;
  if (!isObject(right) && isObject(left)) return left;

  const keys = unique([...Object.keys(left as object), ...Object.keys(right as object)]);

  return keys.reduce(
    (acc: object, key) => ({
      ...acc,
      [key]: !left?.[key]
        ? right?.[key] // Use element from right of the one from left is undefined
        : !right?.[key]
        ? left?.[key] // Use element from left of the one from right is undefined
        : mergeObjectsConcatenatingArrays(left[key], right[key], getSignature), // Merge elements
    }),
    {}
  ) as T;
};

const hasType = (item: unknown): item is { type: string } =>
  typeof item === "object" && typeof item?.["type"] === "string";

const hasId = (item: unknown): item is { id: string } => typeof item === "object" && typeof item?.["id"] === "string";

export const signatureFromTypeAndId = (item: unknown) =>
  `${hasType(item) ? item.type : "notype"}:${hasId(item) ? item.id : "noid"}`;
