import { useCallback, useEffect, useState } from "react";

/**
 * Used to give immediate feedback to the user on clicking load, updates when loading is finished
 */
export const useOnClickLoad = (currentNumberOfResults: number) => {
  const [numberOfResultsWhenClicked, setNumberOfResultsWhenClicked] = useState<number | undefined>(undefined);

  const onClickLoad = useCallback(
    () => setNumberOfResultsWhenClicked(currentNumberOfResults),
    [currentNumberOfResults]
  );

  const isLoading = typeof numberOfResultsWhenClicked !== "undefined";

  useEffect(() => {
    if (isLoading && currentNumberOfResults > numberOfResultsWhenClicked) {
      setNumberOfResultsWhenClicked(undefined);
    }
  }, [currentNumberOfResults, isLoading, numberOfResultsWhenClicked]);

  return { onClickLoad, isLoading };
};
