import {
  Box,
  BsX,
  Button,
  ChakraProps,
  colors,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
} from "@biblioteksentralen/js-utils";
import { ChangeEventHandler, FormEventHandler, MutableRefObject, useEffect, useRef } from "react";
import { DefaultContainer } from "../../../components/ContentContainer";
import { useOnClickLoad } from "../../../utils/hooks/useOnClickLoad";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useUrlQueryParameters } from "../../../utils/hooks/useUrlQueryParameters";
import { searchParameterName } from "../../searchApi";
import { useSitePalette } from "../../../utils/useSitePalette";
import { useSearchConfig } from "../../hooks/useIsSearchIntegrationEnabled";

// TODO: Should the submit button be a link, since it acts like one?

type SearchInputProps = {
  id: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSubmit: FormEventHandler;
  onAfterSubmit?: () => void;
  clear: () => void;
  label?: string;
  loading?: boolean;
  active?: boolean;
  autoFocus?: boolean;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
} & ChakraProps;

export const SearchInput = ({
  id,
  onSubmit,
  onAfterSubmit,
  value,
  onChange,
  clear,
  label,
  autoFocus = false,
  inputRef: inputRefFromProps,
  ...chakraProps
}: SearchInputProps) => {
  const { t } = useTranslation();
  const localInputRef = useRef<HTMLInputElement | null>(null);
  const inputRef = inputRefFromProps ?? localInputRef;
  const { isSearchIntegrationEnabled } = useSearchConfig();
  const paletteColor = useSitePalette().colors.search;

  const handleClear: FormEventHandler = () => {
    clear();
    inputRef.current?.focus();
  };

  const {
    parameterValues: [searchQuery],
  } = useUrlQueryParameters([searchParameterName]);

  const { onClickLoad, isLoading } = useOnClickLoad(searchQuery === value ? 1 : 0);

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    if (!value || value === searchQuery) return;
    onClickLoad();
    onSubmit(event);
  };

  useEffect(() => {
    if (onAfterSubmit && isLoading && searchQuery === value) onAfterSubmit();
  }, [isLoading, onAfterSubmit, searchQuery, value]);

  // TODO: placeholder text
  const placeholder = isSearchIntegrationEnabled
    ? t("Søk etter bøker, arrangementer og personer")
    : t("Søk i samlingen");

  return (
    <Box
      as="form"
      role="search"
      id={id}
      flexDirection="column"
      onSubmit={handleSubmit}
      width="100%"
      padding={{ base: "1rem 0", lg: "2rem 0" }}
      {...paletteColor.css}
      {...chakraProps}
    >
      <DefaultContainer>
        <HStack
          position="relative"
          width="100%"
          maxWidth="container.lg"
          minWidth="8rem"
          alignItems="flex-end"
          spacing="1rem"
        >
          <InputGroup
            size="lg"
            // disable webkit styling in the input field to avoid border-radius looking strange with the dropdown
            // also disable default cancel cross icon in Chrome and otherWebkit browsers
            sx={{
              'input[type="search"]': { "-webkit-appearance": "none" },
              'input[type="search"]::-webkit-search-cancel-button': { display: "none" },
            }}
          >
            <Input
              autoComplete="off"
              name="search"
              ref={inputRef}
              autoFocus={autoFocus}
              type="search"
              aria-label={placeholder}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              _hover={{ backgroundColor: "rgba(0,0,0,.05)" }}
              transition=".25s"
              _placeholder={{ color: paletteColor.isBright ? colors.grey90 : colors.grey10 }}
              _focusVisible={{
                backgroundColor: "white",
                padding: "0 1rem",
                color: colors.grey90,
                _placeholder: { color: colors.grey90 },
              }}
              label={label ?? t("Søk")}
              hideLabel
              variant="flushed"
              backgroundColor="transparent"
              borderBottom={`${paletteColor.isBright ? colors.grey90 : colors.grey10} .125rem solid`}
            />
            {isLoading && (
              <InputRightElement aria-hidden bottom="2px" top="unset">
                <Spinner size="sm" width="1.5rem" height="1.5rem" />
              </InputRightElement>
            )}
            {!isLoading && isSearchIntegrationEnabled && value && (
              <InputRightElement
                as="button"
                type="button"
                onClick={handleClear}
                aria-label={t("Nullstill søk")}
                bottom="0"
                top="unset"
                cursor="pointer"
                _hover={{ backgroundColor: "rgba(0,0,0,.05)" }}
                transition="0.3s ease background"
              >
                <BsX width="1.5rem" height="1.5rem" />
              </InputRightElement>
            )}
          </InputGroup>
          <Button type="submit" variant="secondary" size="lg" paddingX={{ md: "2.5rem" }}>
            {t("Søk")}
          </Button>
        </HStack>
      </DefaultContainer>
    </Box>
  );
};
