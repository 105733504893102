import { z } from "zod";
import { components as configurationComponents } from "../types/configuration";
import { components as patronComponents } from "../types/patron";

type BaseError = { info: string; code: number };
type PatronApiError = patronComponents["schemas"]["Error"];
type ConfigurationApiError = configurationComponents["schemas"]["Error"];

export class RediaPlatformError<ErrorType extends BaseError> extends Error {
  errors: ErrorType[];
  code: ErrorType["code"] | undefined;
  info: ErrorType["info"] | undefined;
  constructor(error: { errors: ErrorType[] }) {
    const errors = error.errors ?? [];
    super(errors.map(({ code, info }) => `[${code}] ${info}`).join(" "));
    this.errors = errors;
    this.code = errors?.[0]?.code;
    this.info = errors?.[0]?.info;
  }
}

export class RediaPlatformConfigurationApiError extends RediaPlatformError<ConfigurationApiError> {}

export class RediaPlatformPatronApiError extends RediaPlatformError<PatronApiError> {}

export const errorResponseSchema = z.object({
  errors: z.array(
    z.object({
      code: z.number(),
      info: z.string(),
    })
  ),
});

/**
 * Thrown when a request cannot be fulfilled because there is no user authenticated.
 */
export class Unauthenticated extends Error {
  constructor() {
    super("No user is currently authenticated");
  }
}
