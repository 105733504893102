import { Box, Heading, Link, Stack, Text } from "@biblioteksentralen/js-utils";
import { getSiteUrl, richTextHasContent } from "@libry-content/common";
import { PrivacyPolicy } from "@libry-content/types";
import format from "date-fns/format";
import max from "date-fns/max";
import { ResolvedSite } from "../../utils/fetchWithCommonDataAndCurrentSiteFilter";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { generatePrivacyPolicyUrl } from "../../utils/librarySystemPrivacyPolicy";
import { DefaultContainer } from "../ContentContainer";
import BlockContent, { richTextStyle } from "../blockContent/BlockContent";
import { Edit } from "../editInSanity/EditInSanity";

export const PRIVACY_POLICY_PATH = "/personvern";
const DATATILSYNET_LINK = "https://www.datatilsynet.no/";

const HARDCODED_TEXT_UPDATED = "2023-11-01T07:31:50.510Z";
const HARDCODED_TEXT_VERSION = "2";

export const getPrivacyPolicyPublishedDate = (
  site: ResolvedSite,
  privacyPolicy: Pick<PrivacyPolicy, "trackUpdatedFields">
): Date => {
  const candidateDates = [new Date(HARDCODED_TEXT_UPDATED), new Date(site._createdAt)];

  if (privacyPolicy?.trackUpdatedFields?.updated) {
    candidateDates.push(new Date(privacyPolicy?.trackUpdatedFields?.updated));
  }

  return max(candidateDates);
};

type Props = {
  site: ResolvedSite;
  privacyPolicy: PrivacyPolicy;
};

const PrivacyPolicyView = ({ site, privacyPolicy }: Props) => {
  const { t, ts } = useTranslation();
  const email = privacyPolicy?.privacyPolicyContactEmail;
  const librarySystemPrivacyPolicyUrl = generatePrivacyPolicyUrl(site.librarySystem);
  const publishedDate = format(getPrivacyPolicyPublishedDate(site, privacyPolicy), "dd.MM.yyyy");
  const path = `${getSiteUrl(site)}${PRIVACY_POLICY_PATH}`;
  const privacyPolicyAddendum = ts(privacyPolicy?.privacyPolicyAddendum);

  return (
    <DefaultContainer>
      <Box sx={richTextStyle} fontSize="lg">
        {privacyPolicy && (
          <Edit
            doc={privacyPolicy}
            marginTop="1rem"
            textAlign="center"
            position="relative"
            left="50%"
            transform="translateX(-50%)"
          />
        )}
        <Heading as="h1" marginTop="2rem" marginBottom="1rem">
          {t("Personvern")}
        </Heading>
        <Text>
          {t("Denne personvernerklæringen beskriver hvordan Biblioteket samler inn og bruker personopplysninger.")}{" "}
          {t("Vi gjør vårt beste for å beskytte personopplysningene dine, også de som ikke er sensitive.")}{" "}
          {t("Med personopplysninger menes opplysninger og vurderinger som kan knyttes til deg som enkeltperson.")}
        </Text>

        <Heading as="h2">{t("Behandlingsansvarlig")}</Heading>
        <Text>
          {t(
            "Biblioteket, ved Biblioteksjefen, er ansvarlig for behandlingen av personopplysninger i forbindelse med våre tjenester."
          )}
        </Text>

        <Heading as="h2">{t("Versjoner")}</Heading>
        <Text>
          {t("Dette er versjon {versjon} av personvernerklæringen.", {
            versjon: `${HARDCODED_TEXT_VERSION}.${privacyPolicy?.trackUpdatedFields?.version ?? 0}`,
          })}{" "}
          {t("Den ble publisert på")} {<Link href={path}>{path.replace(/^https?:\/\//, "")}</Link>}{" "}
          {t("den {dato}.", { dato: publishedDate })}
        </Text>

        <Heading as="h2">{t("Hvem deler vi våre opplysninger med?")}</Heading>
        <Text>
          {t(
            "Personopplysningene dine vil bli gjort tilgjengelig for de ansatte som trenger å se dem for å kunne utføre sine funksjoner på biblioteket som gjelder tjenestene du har bedt om eller har avtalt å bruke."
          )}{" "}
          {t(
            "Biblioteket benytter seg av tredjepartsleverandører til å utføre enkelte funksjoner eller for å tilby enkelte tjenester på våre vegne."
          )}{" "}
          {t(
            "Biblioteket har inngått databehandleravtaler med alle sine databehandlere for å sikre at personopplysningene dine blir behandlet på riktig måte."
          )}
        </Text>

        <Heading as="h2">{t("Nettsider og app")}</Heading>

        <Heading as="h3">{t("Informasjonskapsler (cookies) og webstatistikk")}</Heading>
        <Text>
          {t("Nettsidene er utviklet av Biblioteksentralen i samarbeid med biblioteket.")}{" "}
          {t(
            "Informasjonskapsler (cookies) er små tekstfiler som plasseres på datamaskinen din når du laster ned en nettside."
          )}{" "}
          {t("Biblioteksentralen bruker ikke cookies til å generere statistikk.")}{" "}
          {t("Vi kan dermed ikke spore din bruk av nettstedet tilbake til deg som enkeltperson.")}
        </Text>
        <Text>
          {t(
            "Nettsidene benytter Plausible som analyseverktøy for å samle inn opplysninger om besøkende på våre nettsider."
          )}{" "}
          {t("Plausible benytter ikke informasjonskapsler og all data om besøkende er anonymiserte.")}
        </Text>
        <Text>
          {t("Appen Biblioteket er utviklet av Redia og bruker Google Analytics som analyseverktøy.")}{" "}
          {t("Det benyttes ikke cookies i appen.")}
        </Text>

        <Heading as="h3">{t("Sikkert nettsted")}</Heading>
        <Text>
          {t("Biblioteksentralen benytter HTTPS for å sikre våre nettløsninger mot hacking.")}{" "}
          {t(
            "HTTPS gjør at kommunikasjon mellom nettleseren i din datamaskin/mobile enhet og serveren som driver nettstedet skjer på en sikker måte."
          )}
        </Text>

        <Heading as="h3">{t("Eksterne lenker")}</Heading>
        <Text>
          {t("Biblioteket er ikke ansvarlig for personvern på direktekoblinger utenfor bibliotekets nettsider.")}
        </Text>

        <Heading as="h3">{t("Personopplysninger som samles inn")}</Heading>
        <Text>{t("Følgende data behandles:")}</Text>
        <Stack as="ul" spacing={0}>
          <Box as="li">{t("Navn, postadresse, e-postadresse, fødselsdato og telefonnummer")}</Box>
          <Box as="li">{t("Innloggingsopplysninger")}</Box>
          <Box as="li">{t("Aktive lån, reservasjoner")}</Box>
          <Box as="li">{t("Lånehistorikk, hvis det er gitt samtykke til dette")}</Box>
          <Box as="li">{t("Utestående gebyr og titler knyttet til gebyr")}</Box>
          <Box as="li">{t("Meldingspreferanser")}</Box>
          <Box as="li">{t("Titler i huskeliste")}</Box>
        </Stack>

        <Heading as="h3">{t("Mine sider / biblioteksystem")}</Heading>
        <Text>
          {/* TODO: Does this need updating? */}
          {t("Innlogging på Mine sider / Mappa mi baserer seg på data fra bibliotekets kjernesystem.")}{" "}
          {librarySystemPrivacyPolicyUrl && (
            <>
              {"Personvernerklæring for dette finner du"} <Link href={librarySystemPrivacyPolicyUrl}>{t("her")}</Link>
              {"."}
            </>
          )}
        </Text>
        {richTextHasContent(privacyPolicyAddendum) && (
          <>
            <Heading as="h2">{t("Annen relevant informasjon")}</Heading>
            {/* In order to have the block content headings subordinat to the heading above, we transform h2 headings to h3 */}
            <BlockContent blocks={privacyPolicyAddendum} styleMap={{ h2: "h3" }} />
          </>
        )}

        <Heading as="h2">{t("Har du spørsmål til vår behandling av personopplysninger?")}</Heading>
        <Text>
          {t(
            "Alle har rett til grunnleggende informasjon om behandlingen av personopplysninger i en virksomhet etter personopplysningslovens § 18, 1. ledd."
          )}{" "}
          {t(
            "Biblioteket har gitt informasjonen i denne erklæringen, og vil henvise til den ved eventuelle forespørsler."
          )}
        </Text>
        <Text>
          {t(
            "Alle som er registrert i ett eller flere av Bibliotekets systemer har rett på innsyn i egne opplysninger."
          )}{" "}
          {t(
            "Du har også rett til å be om at uriktige, ufullstendige eller opplysninger biblioteket ikke har adgang til å behandle blir rettet, slettet eller supplert."
          )}
        </Text>

        <Heading as="h2">{t("Klageorgan")}</Heading>
        <Text>
          {t(
            "Hvis du mener at Biblioteket har behandlet personopplysningene i strid med personvernlovgivningen kan du til enhver tid klage til"
          )}{" "}
          <Link href={DATATILSYNET_LINK}>{t("Datatilsynet")}</Link>
          {"."}
        </Text>

        <Heading as="h2">{t("Endringer")}</Heading>
        <Text>
          {t(
            "Biblioteket kan revidere denne personvernerklæringen hvis det skjer endringer i behandlingen av personopplysninger, eller ved endringer i gjeldende lovgivning."
          )}{" "}
          {t("Når personvernerklæringen endres, vil den nye personvernerklæringen publiseres på nettsidene våre.")}
        </Text>

        {email && (
          <>
            <Heading as="h2">{t("Kontaktinformasjon")}</Heading>
            <Box>
              <Text>{t("E-post: {epost}", { epost: email })}</Text>
            </Box>
          </>
        )}
      </Box>
    </DefaultContainer>
  );
};

export default PrivacyPolicyView;
