import { useRouter } from "next/router";
import { ChangeEventHandler, ComponentProps, FormEventHandler, Ref, useState } from "react";
import { handleLegacyCatalogSearch } from "../../../components/layout/LegacyCatalogSearch";
import { searchParameterName } from "../../searchApi";
import { SearchInput } from "./SearchInput";
import { searchPagePath } from "../../hooks/useIsOnSearchPage";
import { useSearchConfig } from "../../hooks/useIsSearchIntegrationEnabled";

type HeaderSearchInputProps = {
  inputRef?: Ref<HTMLInputElement | null>;
} & Omit<ComponentProps<typeof SearchInput>, "onSubmit" | "value" | "onChange" | "clear">;

export const HeaderSearchInput = ({ inputRef, ...props }: HeaderSearchInputProps) => {
  const { push } = useRouter();
  const [inputValue, setInputValue] = useState("");
  const { isSearchIntegrationEnabled } = useSearchConfig();

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => setInputValue(event.target.value);

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault();

    if (isSearchIntegrationEnabled) {
      const href = `${searchPagePath}?${searchParameterName}=${encodeURIComponent(inputValue)}`;
      push(href);
      return;
    }

    handleLegacyCatalogSearch(inputValue);
  };

  const clear = () => {
    setInputValue("");
  };

  return (
    <SearchInput
      onSubmit={onSubmit}
      value={inputValue}
      onChange={onChange}
      clear={clear}
      inputRef={inputRef}
      {...props}
    />
  );
};
