import { useTranslation } from "../../../utils/hooks/useTranslation";
import { Box, Text } from "@biblioteksentralen/js-utils";
import { ReactNode } from "react";
import { headerLinkDecorationStyle } from "../utils";
import { useCommonData } from "../CommonDataProvider";

const Name = ({ children, gap }: { children: ReactNode; gap: boolean }) => {
  // Dersom man har lagt inn biblioteksnavnet med mellomrom i editor må mellomrommet tas vare på. Pga at logo skal wrappe over flere linjer på små skjermer bruker vi her inline-flex, men den fjerner samtidig mellomrom. Må derfor legge til gap for å ta vare på mellomrom.
  // Eks skal beholde mellomrom "Holmestrand bibliotek"
  // Eks skal ikke ha mellomrom: "Sandefjordbibliotekene"

  return (
    <Text
      textTransform="uppercase"
      _groupHover={{ textDecorationLine: "underline" }}
      lineHeight="1"
      transform="translateY(.1em)"
      fontSize={{ base: "md", sm: "lg", lg: "xl" }}
      sx={headerLinkDecorationStyle()}
      display="inline-flex"
      flexWrap="wrap"
      gap={gap ? "0 0.25rem" : undefined}
    >
      {children}
    </Text>
  );
};

// Slenger på sort fordi de lengste strengene bør gi treff først. Hvis ikke "folkebibliotek" ligger før "bibliotek" får vi feks rare treff som "Tysnes folke"
const knownEndingsAndBeginnings = ["folkebibliotek", "bibliotek", "bibliotekene", "ryfylkebiblioteket"].sort(
  (a, b) => b.length - a.length
);

const FormattedSiteName = () => {
  const { ts } = useTranslation();
  const { site } = useCommonData();

  const siteName = ts(site?.name)?.trim();

  const notBoldEnding = knownEndingsAndBeginnings.find((ending) => siteName?.toLowerCase().endsWith(ending));

  const notBoldBeginning = notBoldEnding
    ? undefined
    : knownEndingsAndBeginnings.find((ending) => siteName?.toLowerCase().startsWith(ending));

  if (notBoldEnding) {
    const secondaryPartIndex = siteName?.toLowerCase()?.indexOf(notBoldEnding);
    const siteNameParts = {
      boldPart: siteName?.slice(0, secondaryPartIndex),
      notBoldPart: siteName?.slice(secondaryPartIndex),
    };

    return (
      <Name gap={siteNameParts.boldPart?.endsWith(" ") ?? false}>
        <Box as="span" fontWeight={600}>
          {siteNameParts.boldPart}
        </Box>
        {siteNameParts.notBoldPart && <Box as="span">{siteNameParts.notBoldPart}</Box>}
      </Name>
    );
  }

  if (notBoldBeginning) {
    const secondaryPartIndex = notBoldBeginning.length;
    const siteNameParts = {
      notBoldPart: siteName?.slice(0, secondaryPartIndex),
      boldPart: siteName?.slice(secondaryPartIndex),
    };

    return (
      <Name gap={siteNameParts.boldPart?.startsWith(" ") ?? false}>
        {siteNameParts.notBoldPart && <Box as="span">{siteNameParts.notBoldPart}</Box>}
        <Box as="span" fontWeight={600}>
          {siteNameParts.boldPart}
        </Box>
      </Name>
    );
  }

  return (
    <Name gap={false}>
      <Box as="span" fontWeight={600}>
        {siteName}
      </Box>
    </Name>
  );
};

export default FormattedSiteName;
