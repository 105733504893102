import { Box } from "@biblioteksentralen/js-utils";
import { PropsWithChildren, ReactNode, useRef } from "react";
import { RediaPlatformProvider } from "../../rediaPlatform//RediaPlatformProvider";
import { SearchContextProvider } from "../../searchAndCollection/components/search/SearchContext";
import { ShowForGlobalAdmins } from "../../utils/ShowForGlobalAdmins";
import { CommonData } from "../../utils/fetchWithCommonDataAndCurrentSiteFilter";
import { useTranslation } from "../../utils/hooks/useTranslation";
import SkipNavButton from "../SkipNavButton";
import { SOMEPreview } from "../preview/SOMEPreview";
import { CommonDataProvider } from "./CommonDataProvider";
import { LayoutGrid } from "./LayoutGrid";
import { Footer } from "./footer/Footer";
import AdminPanel from "./header/AdminPanel";
import { Header } from "./header/Header";
import { PaletteProvider } from "../../utils/useSitePalette";

interface Props {
  commonData: CommonData;
  withoutDefaultLayout?: boolean;
  withSOMEPreviewForAdmins?: boolean;
}

const CommonProviders = (props: { children: ReactNode; commonData: CommonData }) => (
  <CommonDataProvider commonData={props.commonData}>
    <PaletteProvider>
      <RediaPlatformProvider>
        <SearchContextProvider>{props.children} </SearchContextProvider>
      </RediaPlatformProvider>
    </PaletteProvider>
  </CommonDataProvider>
);

function CommonPageWrapper(props: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <CommonProviders commonData={props.commonData}>
      <LayoutGrid>
        <AdminPanel />
        <SkipNavButton title={t("Hopp til hovedinnhold")} focusOnClick={() => ref.current?.focus()} />
        <Header />
        <Box
          id="main"
          as="main"
          maxWidth="100vw"
          tabIndex={-1}
          ref={ref}
          margin={props.withoutDefaultLayout ? undefined : "1rem 0 6rem"}
        >
          {props.children}
        </Box>
        <Footer />
        {props.withSOMEPreviewForAdmins && (
          <ShowForGlobalAdmins>
            <SOMEPreview />
          </ShowForGlobalAdmins>
        )}
      </LayoutGrid>
    </CommonProviders>
  );
}

export default CommonPageWrapper;
