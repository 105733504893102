import {
  Link as ChakraLink,
  FaFacebookF,
  FaInstagram,
  StackProps,
  VisuallyHidden,
  YoutubeIcon,
} from "@biblioteksentralen/js-utils";
import { HStack } from "@biblioteksentralen/js-utils";
import { SocialMedia } from "@libry-content/types";
import { useTranslation } from "../../utils/hooks/useTranslation";

const Link: typeof ChakraLink = (props) => (
  <ChakraLink
    display="flex"
    justifyContent="center"
    alignItems="center"
    color="inherit"
    fontSize="1.1rem"
    borderRadius="50%"
    padding="0.4rem"
    aspectRatio="1"
    _hover={{ transition: "0.3s", backgroundColor: "hsla(0deg, 0%, 50%, 0.3)" }}
    {...props}
  />
);

interface Props extends StackProps {
  socialMedia?: SocialMedia;
}

function SocialMediaView({ socialMedia, ...stackProps }: Props) {
  const { t } = useTranslation();
  if (!socialMedia?.facebook && !socialMedia?.instagram && !socialMedia?.youtube) return null;

  return (
    <HStack {...stackProps}>
      {socialMedia?.facebook && (
        <Link href={socialMedia.facebook}>
          <FaFacebookF />
          <VisuallyHidden>{t("Besøk oss på {sosialtMedie}", { sosialtMedie: "facebook" })}</VisuallyHidden>
        </Link>
      )}{" "}
      {socialMedia?.instagram && (
        <Link href={socialMedia.instagram}>
          <FaInstagram />
          <VisuallyHidden>{t("Besøk oss på {sosialtMedie}", { sosialtMedie: "instagram" })}</VisuallyHidden>
        </Link>
      )}{" "}
      {socialMedia?.youtube && (
        <Link href={socialMedia.youtube}>
          <YoutubeIcon width="1.5rem" height="1.5rem" />
          <VisuallyHidden>{t("Besøk oss på {sosialtMedie}", { sosialtMedie: "youtube" })}</VisuallyHidden>
        </Link>
      )}
    </HStack>
  );
}

export default SocialMediaView;
