import { useBoolean } from "@biblioteksentralen/js-utils";
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { mergeObjectsConcatenatingArrays } from "../../../utils/mergeObjectsConcatenatingArrays";
import { emptySearchResults, SearchResults } from "../../types";

export interface SearchContext {
  searchQuery: string | undefined;
  setSearchQuery: (updatedQuery: string | undefined) => void;
  searchResults: SearchResults;
  updateSearchResults: (updatedResults: SearchResults) => void;
  setSearchResults: (results: SearchResults) => void;
  loading: boolean;
  onLoading: () => void;
  onLoadingFinished: () => void;
}

const noop = () => {};

export const emptySearchContext = {
  searchQuery: undefined,
  setSearchQuery: noop,
  searchResults: emptySearchResults,
  updateSearchResults: noop,
  setSearchResults: noop,
  loading: false,
  onLoading: noop,
  onLoadingFinished: noop,
};

export const SearchContext = createContext<SearchContext>(emptySearchContext);

export const useSearchContext = () => useContext(SearchContext);

type SearchContextProviderProps = {
  children: ReactNode;
};

export const SearchContextProvider = ({ children }: SearchContextProviderProps) => {
  const [searchResults, setSearchResults] = useState<SearchResults>(emptySearchContext["searchResults"]);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(emptySearchContext["searchQuery"]);
  const [elementIdToFocus, setElementIdToFocus] = useState<string | undefined>();
  const [loading, { on: onLoading, off: onLoadingFinished }] = useBoolean();

  const updateFocusState = (updatedResults: Partial<SearchResults>) => {
    const firstNewWork = updatedResults.works?.[0];
    const firstNewMovie = updatedResults.movies?.[0];
    const firstNewAgent = updatedResults.agents?.[0];
    firstNewWork && setElementIdToFocus(`bibbi-work:${firstNewWork?.id}`);
    firstNewMovie && setElementIdToFocus(`bibbi-work:${firstNewMovie?.id}`);
    firstNewAgent && setElementIdToFocus(`bibbi-agent:${firstNewAgent?.id}`);
  };

  const updateSearchResults = useCallback((updatedResults: SearchResults) => {
    // We don't want to remove duplicates here (thus the undefined equality comparison function).
    // The resulting lower number due to removed duplicates will confuse the search pagination,
    // leading to an infinite loop. It's better to keep the duplicates and deal with them downstream.
    setSearchResults((currentResults) => mergeObjectsConcatenatingArrays(currentResults, updatedResults, undefined));
    updateFocusState(updatedResults);
  }, []);

  useEffect(() => {
    if (elementIdToFocus) {
      const elementToFocus = document.getElementById(elementIdToFocus);
      elementToFocus?.focus();
    }
  }, [elementIdToFocus]);

  return (
    <SearchContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        searchResults,
        updateSearchResults,
        setSearchResults,
        loading,
        onLoading,
        onLoadingFinished,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
