import { BiSearch, Box, Button, ButtonProps, Text } from "@biblioteksentralen/js-utils";
import { MouseEventHandler } from "react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { headerLinkDecorationStyle } from "../utils";

type HeaderSearchButtonProps = { onClick: MouseEventHandler; selected: boolean } & ButtonProps;

export const HeaderSearchButton = ({ onClick, selected, ...buttonProps }: HeaderSearchButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button variant="plain" onClick={onClick} sx={headerLinkDecorationStyle(selected)} {...buttonProps}>
      <Box>
        <BiSearch />
      </Box>
      <Text marginLeft="0.25rem">{t("Søk")}</Text>
    </Button>
  );
};
